import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import type { Clinic } from '@app-model/clinic';
import type { ApiResponseList } from '@app-model/application';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {

  constructor(private http: HttpClient) { }

  get(): Observable<ApiResponseList<Clinic>> {
    return this.http.get<ApiResponseList<Clinic>>(`${environment.api}/clinic`);
  }

  create(body, id:string): Observable<any> {
    return this.http.post(`${environment.api}/clinic/create/${id}`, body);
  }

  update(body): Observable<any> {
    return this.http.put(`${environment.api}/clinic`, body);
  }

  delete(idClinic: string, idDoctor: string): Observable<any> {
    return this.http.delete(`${environment.api}/clinic/${idClinic}/doctor/${idDoctor}/delete`);
  }
}
