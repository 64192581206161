import { Schedule } from './schedule';

export interface PatientPageableResponse {
	actualPage: number;
	data: Patient[];
	numberOfPage: number;
	totalElements: number;
}

export interface Patient {
	accessToken: string;
	accessTokenExpiration: Date;
	birthDate: Date;
	cancelToken: string;
	cpf: string;
	createdAt: Date;
	email: string;
	end: Date;
	healthPlanId: string;
	id: string;
	information: string;
	name: string;
	particular: boolean;
	patientId: string;
	question1: boolean;
	question2: boolean;
	reminderEmail: boolean;
	schedule: Schedule;
	scheduleId: string;
	start: Date;
	status: string;
	telephone: string;
	title: string;
	type: string;
	updatedAt: Date;
	value: number;
	visitType: string;
  photo: string;
  address?: string;
  cep?: string;
  city?: string;
  neighborhood?: string;
  number?: string;
  uf?: string;
  credits?: number;
  cns?: string;
  motherName?: string;
};

export interface PatientHistory {
	id: string;
	title: string;
	start: Date;
	end: Date;
	scheduleId: string;
	status: string;
	type: string;
	createdAt: Date;
	updatedAt: Date;
	visitType?: any;
	particular: boolean;
	question1: boolean;
	information?: any;
	patientId: string;
	name: string;
	email: string;
	telephone: string;
	cpf?: any;
	clinicName: string;
	cityId: string;
	city: string;
	uf: string;
	address: string;
	neighborhood: string;
	number: string;
	doctor: string;
	specialty: string;

	date?: string;
	dateDayOfWeek?: string;
	clinicAddress?: string;
}

export interface CreatePatient {
  photo: File | string;
  name: string;
  cpf: string;
  birthDate: Date;
  telephone: string;
  email: string;
  cep?: string;
  address?: string;
  number?: string;
  addition?: string;
  neighborhood?: string;
  uf?: string;
  city?: string;
  cityId?: string;
  password: string;
}

export interface PatientPageableResponse {
	actualPage: number;
	data: Patient[];
	numberOfPage: number;
	totalElements: number;
}

export interface PatientHistoryPageableResponse {
	actualPage: number;
	data: PatientHistory[];
	numberOfPage: number;
	totalElements: number;
}

export class ProductStatus {
	id: string;
	description: string;
	statusCode: string;
}

export enum CreditStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  WAITING = 'WAITING',
}
