import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { PageControl } from '@app-model/page-control';
import { Patient } from '@app-model/patient';
import { PatientService } from '@app-services/patient.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

interface PatientChecked extends Patient {
  checked: boolean;
}

@Component({
  selector: 'app-table-patients',
  templateUrl: './table-patients.component.html',
  styleUrls: ['./table-patients.component.scss']
})
export class TablePatientsComponent implements OnInit {

	@Input() searchTerm = '';
  @Input() idDoctor?: string;
	@Input() loading = false;

  @Output() checkedPatients = new EventEmitter<PatientChecked[]>()
  @Output() clickPatient = new EventEmitter<Patient>();

  public list: PatientChecked[] = [];

	public columns = [
		{
			slug: 'name',
			order: true,
			title: 'NOME DO PACIENTE'
		},
		{
			slug: 'email',
			order: true,
			title: 'E-MAIL'
		},
		{
			slug: 'telephone',
			order: true,
			title: 'TELEFONE'
		},
		{
			slug: 'cns',
			order: false,
			title: 'CNS'
		},
		// {
		// 	slug: 'credits',
		// 	order: true,
		// 	title: 'CRÉDITOS'
		// },
	];

	public pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'createdAt',
		order: 'DESC',
		count: 0
	};

  allComplete: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.searchPatients();
  }

  ngOnChanges(changes: SimpleChanges): void {
		const { searchTerm, idDoctor, loading, removePatientEvent } = changes;

		if ( (searchTerm?.previousValue && searchTerm?.currentValue !== searchTerm?.previousValue) ||
        (idDoctor?.currentValue !== idDoctor?.previousValue) ) {
			this.onSearch();
		} else if(!loading?.currentValue) {
			this.onSearch();
    }

	}

  onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
		this.searchPatients();
	}

	pageEvent($event) {
		this.pageControl.page = $event.pageIndex;
		this.pageControl.limit = $event.pageSize;
		this.searchPatients();
	}

  private onSearch() {
		this.pageControl.value = this.searchTerm;
		this.pageControl.page = 0;
		this.searchPatients();
	}

  private searchPatients() {
    this.initOrStopLoading();

    this.patientService.paginate(this.pageControl)
      .pipe(finalize(() => this.initOrStopLoading()))
      .subscribe({
        next: res => this.searchPatientsResponse(res),
        error: err => this.searchPatientsError(err)
      })
  };

  private searchPatientsResponse(res): void {
    this.list = res.data;
    this.pageControl.count = res.totalElements;

    this.handleCheckedPatients();
  }

  private searchPatientsError(err): void {
    console.error(err)
  }

  private initOrStopLoading(): void {
    this.loading = !this.loading;
  };

  handleCheckedPatients(): void {
    const patients = this.list.filter((patient: any) => {
      if(patient.checked){
        return patient;
      }
    })

    this.checkedPatients.emit(patients);
  }

  handleClickPatient(patient: Patient): void {
    this.clickPatient.emit(patient);
  }

  updateAllComplete() {
    this.allComplete = this.list != null && this.list.every(t => t.checked);

    this.handleCheckedPatients();
  }

  someComplete(): boolean {
    if (this.list == null) {
      return false;
    }
    return this.list.filter(t => t.checked).length > 0 && !this.allComplete;
  }

  setAll(checked: boolean) {
    this.allComplete = checked;
    if (this.list == null) {
      return;
    }
    this.list.forEach(t => (t.checked = checked));

    this.handleCheckedPatients();
  }

}
